import React from "react";
import Main from "../Main/Main";
import Sidebar from "../Sidebar/SideBar";

function Common() {
  return (
    <>
      <Main />
      <Sidebar />
    </>
  );
}

export default Common;
