import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Row, Stack, Card, Col } from "react-bootstrap";
import "./empdash.css";
import { CSVLink } from "react-csv";
import html2pdf from "html2pdf.js";
import { useNavigate } from "react-router-dom";
import { StaffAuthContext } from "../../../context/StaffAuthContext";
import logo from "../../../images/logoblack_trans.png";

function EmpDash({ toggleLoading }) {
  const { userId, userPosition } = useContext(StaffAuthContext);

  //to add employee button part
  const navigate = useNavigate();

  //to all emplyees
  const [tableData, setTableData] = useState([]);

  //for search part
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchEmployeeID, setSearchEmployeeID] = useState("");
  const [searchPosition, setSearchPosition] = useState("");

  //to all employee details fetch
  useEffect(() => {
    const fetchData = async () => {
      try {
        toggleLoading(true); // Set loading to true before API call
        const response = await fetch(
          `${process.env.React_App_Backend_URL}/api/hr/employees`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        setTableData(data.employees);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        toggleLoading(false); // Set loading to false after API call
      }
    };

    fetchData();
  }, []); // Runs once on component mount

  //table headers
  const columns = ["EmployeeId", "First", "Last", "Role", "Contact", ""];

  //reagarding modal
  const handleMoreButtonClick = async (employeeId) => {
    // Navigate to another URL when the "More" button is clicked
    navigate(`empDetails/${employeeId}`);
  };

  // Filter table data based on search queries
  useEffect(() => {
    const filteredData = tableData.filter((employee) => {
      const nameMatches =
        employee.firstName.toLowerCase().includes(searchName.toLowerCase()) ||
        employee.lastName.toLowerCase().includes(searchName.toLowerCase());
      const employeeIDMatches = employee.empId
        .toLowerCase()
        .includes(searchEmployeeID.toLowerCase());
      const positionMatches = employee.position
        .toLowerCase()
        .includes(searchPosition.toLowerCase());
      return nameMatches && employeeIDMatches && positionMatches;
    });
    setFilteredTableData(filteredData);
  }, [tableData, searchName, searchEmployeeID, searchPosition]);

  const clearFilters = () => {
    setSearchName("");
    setSearchEmployeeID("");
    setSearchPosition("");
  };

  //CSV Data generation
  const generateCSVData = () => {
    const currentDate = new Date().toLocaleDateString();

    // Add table name and generated date as the first two rows
    const csvData = [
      ...filteredTableData.map((record) => ({
        EmployeeId: record.empId,
        Name: record.firstName + " " + record.lastName,
        Position: record.position,
        Conatct: record.contact,
        NIC: record.nic,
        Address: record.address,
      })),
      { Id: "Employee List" },
      { Id: `Generated Date: ${currentDate}` },
      { Id: `Generated by: Neo Tech Motors & Services` },
    ];

    return csvData;
  };

  /*----Parts regarding generate pdf from employee list-------*/
  const generatePDF = () => {
    const element = document.querySelector(".empList"); // Select the container to convert to PDF
    if (!element) {
      console.error("Container element not found");
      return;
    }

    // Clone the element content to manipulate without affecting the original
    const contentToInclude = element.cloneNode(true);

    // Remove .hrmoreButton elements from the cloned content
    const hrmoreButtons = contentToInclude.querySelectorAll(".hrmoreButton");
    hrmoreButtons.forEach((button) => {
      button.remove();
    });

    // Add header content
    const headerContent = `
      <div >
        <h4 class="float-end font-size-15">Human Resources</h4>
        <div class="mb-4">
          <img src="${logo}" alt="Invoice Logo" width="200px" />
        </div>
        <div class="text-muted">
        <p class="mb-1"><i class="bi bi-geo-alt-fill"></i>323/1/A Main Street Battaramulla</p>
        <p class="mb-1">
        <i class="bi bi-envelope-fill me-1"></i> info@neotech.com
        </p>
        <p>
        <i class="bi bi-telephone-fill me-1"></i> 0112887998
        </p>

          <p>Authorized By: ${userPosition}</p>
      <p>Generated Date: ${new Date().toLocaleDateString()}</p>
        </div>
        <hr/>
      </div>
    `;

    // Create wrapper div to contain both header, employee list, and footer
    const wrapper = document.createElement("div");
    wrapper.innerHTML = headerContent;
    wrapper.appendChild(element.cloneNode(true));

    // Define PDF generation options
    const options = {
      margin: 0.5,
      filename: "Employee_List.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // Generate and save the PDF
    html2pdf()
      .from(wrapper)
      .set(options)
      .save()
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  return (
    <section>
      <Card>
        <Card.Body style={{ backgroundColor: "white", padding: "25px" }}>
          <Row>
            <Stack direction="horizontal" gap={3}>
              <div className="p-2 ms-auto">
                <Button
                  variant="dark"
                  size="md"
                  onClick={() => navigate("add")}
                >
                  Create Employee
                </Button>
                <Button variant="success" size="md" style={{ margin: "10px" }}>
                  <CSVLink
                    data={generateCSVData()}
                    filename={`Employee_List_${new Date().toLocaleDateString()}.csv`}
                  >
                    Create Report(CSV)
                    <span
                      className="bi bi-file-excel"
                      style={{ marginRight: "5px" }}
                    ></span>
                  </CSVLink>
                </Button>
                <Button
                  variant="danger"
                  size="md"
                  onClick={generatePDF}
                  style={{ margin: "10px" }}
                >
                  Create Report(PDF)
                  <span
                    className="bi bi-file-pdf"
                    style={{ marginRight: "5px" }}
                  ></span>
                </Button>
              </div>
              <div className="text-end">
                <Button
                  variant="dark"
                  size="md"
                  onClick={() => navigate("archived-employees")}
                  style={{ margin: "10px" }}
                  id="archiveButton"
                >
                  <i className="bi bi-archive-fill archiveButton"></i>
                </Button>
              </div>
            </Stack>
          </Row>
          <hr />
          <Form>
            <Row>
              <Col md={3}>
                <Form.Group controlId="searchName">
                  <Form.Control
                    type="text"
                    placeholder="Search by name..."
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="searchEmployeeID">
                  <Form.Control
                    type="text"
                    placeholder="Search by employee ID..."
                    value={searchEmployeeID}
                    onChange={(e) => setSearchEmployeeID(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="searchPosition">
                  <Form.Control
                    type="text"
                    placeholder="Search by position..."
                    value={searchPosition}
                    onChange={(e) => setSearchPosition(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Button variant="secondary" onClick={clearFilters}>
                  Clear Search
                </Button>
              </Col>
            </Row>
          </Form>

          <div className="table hr-t empList">
            <table
              className="table table-rounded hr-t"
              style={{
                backgroundColor: "white",
              }}
            >
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th
                      key={index}
                      style={{ backgroundColor: "black", color: "white" }}
                    >
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredTableData.map((employee, index) => (
                  <tr key={index}>
                    <td>{employee.empId}</td>
                    <td>{employee.firstName}</td>
                    <td>{employee.lastName}</td>
                    <td>{employee.position}</td>
                    <td>{employee.contact}</td>
                    <td>
                      <Button
                        variant="dark"
                        className="d-flex mx-auto hrmoreButton"
                        onClick={() => handleMoreButtonClick(employee._id)}
                      >
                        More
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
    </section>
  );
}

export default EmpDash;
